import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Projects extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const project = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Home" />
        <header>
          <div className="container">
            <div className="grid">
              <div className="column-xs-12">
                <h1>Luiz Quenji Kato Junior</h1>
                <p className="lead">Passionate game designer with experience creating UX/UI designs and gameplay mechanics with years of professional training.</p>
              </div>
            </div>
          </div>
        </header>
        <main className="work">
          <div className="container">
            <div className="grid">
              {project.map(({ node }) => {
                return (
                  <div className="column-xs-12 column-md-6">
                    <Link key={node.fields.slug} to={node.fields.slug}>
                      <figure>
                        <Img fluid={node.frontmatter.image.childImageSharp.fluid} backgroundColor={true} />
                        <figcaption>
                          <h3>{node.frontmatter.title}</h3>
                          <p className="lead">{node.frontmatter.role}</p>
                        </figcaption>
                      </figure>
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}

export default Projects

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            role
            image {
              childImageSharp {
                fluid(maxWidth: 1280) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

          }
        }
      }
    }
  }
`
